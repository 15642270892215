<template>
	<div>

	</div>
</template>
<script>
	export default {
		data() {
			return {
			}
		},
		created: function () {
            var self = this;
            mgr.signinSilentCallback().then(function () {
                mgr.getUser().then(function (user) {
                    if (user) {
                        localStorage.headertoken = user.access_token;
                        localStorage.IsSSOUser = "true";
                    }
                });
            });
		},
		methods: {

		}
	}
</script>
